<template>
  <b-card>
    <div class="row mb-5">
      <div class="col-md-3">
        <b-form-input
          id="search"
          v-model="query.search"
          name="search"
          placeholder="Поиск"
          @keyup.enter="onSearch"
          @input="onInputSearch"
        />
      </div>
      <div class="col-md-9 d-md-flex align-items-center justify-content-end">
        <div class="my-1 my-md-0 mx-md-1">
          Экспортировать в .csv:
        </div>
        <div class="d-flex">
          <div class="flat-pickr-wrap mr-1">
            <flat-pickr
              v-model="export_date"
              :config="datePickerConfig"
              placeholder="Выберите период"
            />
          </div>
          <b-button
            variant="primary"
            :disabled="export_date.length > 22 ? false : true"
            @click="exportCsv"
          >
            Экспорт
          </b-button>
        </div>
      </div>
    </div>
    <div
      v-if="requests.length"
    >
      <requests-list
        :order="query.order"
        :order-direction="query.order_direction"
        :requests="requests"
        @toggleRequestProcess="toggleRequestProcess"
        @orderByCreatedAt="orderByCreatedAt"
      />
      <b-pagination
        v-if="total / perPage > 1"
        v-model="query.page"
        :total-rows="total"
        :per-page="perPage"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        align="right"
        @input="onChangePage"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    <div v-else>
      Нет заявок
    </div>
  </b-card>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapGetters } from 'vuex'
import {
  BCard, BFormInput, BPagination, BButton,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Russian } from 'flatpickr/dist/l10n/ru'

import SiteRequest from '@/api/http/models/site-request/SiteRequest'
import RequestsList from './RequestsList.vue'

export default {
  components: {
    BCard,
    BFormInput,
    BPagination,
    RequestsList,
    BButton,
    flatPickr,
  },

  data() {
    return {
      export_date: '',
      datePickerConfig: {
        mode: 'range',
        wrap: true,
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Russian,
      },
      requests: [],
      perPage: 1,
      total: 1,
      query: {
        page: 1,
        order: 'created_at',
        order_direction: 'desc',
        search: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },

  async created() {
    this.query = {
      ...this.query,
      ...this.$route.query,
    }

    await this.fetchRequests()
  },

  methods: {
    async fetchRequests() {
      const response = await SiteRequest.getAll(this.currentSite.slug, this.query)

      this.requests = response.data || []

      this.perPage = response.meta.per_page || 1

      this.total = response.meta.total || 1
    },

    async exportCsv() {
      const dates = this.export_date.split(' — ')
      const response = await SiteRequest.export(this.currentSite.slug, {
        date_from: dates[0],
        date_to: dates[1],
      })
      const downloadUrl = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.setAttribute('href', downloadUrl)
      link.setAttribute('download', `jaky_export(${this.export_date}).csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    updateUrlParams(onlyPages = false) {
      if (onlyPages) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            page: this.query.page,
          },
        })
      } else {
        this.$router.replace({
          query: {
            ...this.query,
          },
        })
      }
    },

    onInputSearch() {
      if (this.search === '') {
        this.onSearch()
      }
    },

    onSearch() {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(this.query)) {
        this.query.page = 1
        this.fetchRequests()
        this.updateUrlParams()
      }
    },

    onChangePage() {
      this.fetchRequests()
      this.updateUrlParams(true)
    },

    orderByCreatedAt() {
      this.query.order = 'created_at'
      this.query.order_direction = this.query.order_direction === 'asc' ? 'desc' : 'asc'
      this.fetchRequests()
      this.updateUrlParams()
    },

    toggleRequestProcess(requestId) {
      const index = this.requests.findIndex(request => request.uuid === requestId)

      if (index !== -1) {
        this.requests[index].is_processed = !this.requests[index].is_processed
      }
    },
  },
}
</script>

<style lang="scss">
  .flat-pickr-wrap .input {
    background: #fff;
  }
</style>
