<template>
  <b-modal
    id="update-request-popup"
    :visible="visible"
    size="lg"
    @ok="handleOk"
    @hidden="$emit('hidden')"
  >
    <template #modal-footer="{ ok, cancel }">
      <b-button
        :variant="request.is_processed ? 'outline-primary' : 'primary'"
        @click="ok()"
      >
        {{ request.is_processed ? 'Вернуть в работу' : 'Заявка обработана' }}
      </b-button>

      <b-button
        variant="outline-primary"
        @click="cancel()"
      >
        Закрыть окно
      </b-button>
    </template>
    <b-tabs>
      <b-tab>
        <template #title>
          Заявка
        </template>
        <single-request
          :request="request"
        />
      </b-tab>
      <b-tab
        v-if="history.length"
      >
        <template #title>
          Все заявки клиента
        </template>
        <history-request
          :request="request"
          :history="history"
          @toggleRequestProcess="toggleRequestProcess"
        />
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import {
  BModal, BTabs, BTab, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

import SiteRequest from '@/api/http/models/site-request/SiteRequest'

import SingleRequest from './SingleRequest.vue'
import HistoryRequest from './HistoryRequest.vue'

export default {
  components: {
    BModal,
    BTabs,
    BTab,
    BButton,
    SingleRequest,
    HistoryRequest,
  },

  props: {
    requestId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      visible: false,
      request: {},
      history: [],
    }
  },

  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },

  async created() {
    const singleRequest = await SiteRequest.getOne(this.currentSite.slug, this.requestId)
    const historyRequest = await SiteRequest.history(this.currentSite.slug, this.requestId)

    this.request = singleRequest.data || {}
    this.history = historyRequest.data || []

    this.visible = true
  },

  methods: {
    async handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      const response = await SiteRequest.processed(this.currentSite.slug, this.requestId, !this.request.is_processed)

      if (response.status === 'success') {
        this.$emit('toggleRequestProcess', this.requestId)
      }

      this.$nextTick(() => {
        this.$bvModal.hide('update-request-popup')
      })
    },

    toggleRequestProcess(requestId) {
      const index = this.history.findIndex(request => request.uuid === requestId)

      if (index !== -1) {
        this.history[index].is_processed = !this.history[index].is_processed
      }

      this.$emit('toggleRequestProcess', requestId)
    },
  },
}
</script>
