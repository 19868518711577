<template>
  <div class="history-item">
    <div class="history-item__title h3">
      <feather-icon
        icon="TagIcon"
        size="21"
        class="mr-1"
      />
      Заявка от 25.04.2021
      <super-tag class="ml-1">
        {{ request.target_translated }}
      </super-tag>
    </div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="mb-2 w-50">
        <p>
          Статус
        </p>
        <p class="pl-2 font-weight-bold">
          <b
            v-if="request.is_processed"
            class="text-success"
          >
            Обработана
          </b>
          <b
            v-else
            class="text-secondary"
          >
            Не обработана
          </b>
        </p>
      </div>
      <div class="mb-2 w-50">
        <p>
          Дата / Время заявки
        </p>
        <p class="pl-2 font-weight-bold">
          {{ request.created_at | momentFormat('DD MMM HH:mm') }}
        </p>
      </div>
      <div class="mb-2 w-50">
        <p>
          Страница
        </p>
        <p class="pl-2 font-weight-bold">
          <a
            :href="request.referer"
            target="_blank"
          >
            {{ request.request_title }}
          </a>
        </p>
      </div>
      <div class="mb-2 w-50">
        <p>
          Место
        </p>
        <p class="pl-2 font-weight-bold">
          {{ request.target_translated }}
        </p>
      </div>
      <div
        v-if="request.message"
        class="mb-2 w-50"
      >
        <p>
          Сообщение
        </p>
        <p class="pl-2 font-weight-bold">
          {{ request.message }}
        </p>
      </div>
      <div
        v-if="request.files.length"
        class="mb-2 w-50"
      >
        <p>
          Прикреплённые файлы:
        </p>
        <div
          v-for="file in request.files"
          :key="file"
        >
          <a
            :href="file"
            target="_blank"
            class="d-block text-one-line-dots"
          >
            {{ file }}
          </a>
        </div>
      </div>
      <div
        v-if="request.additional_info"
        class="mb-2 w-50"
      >
        <p>
          Дополнительная информация
        </p>
        <p class="pl-2 font-weight-bold">
          {{ request.additional_info }}
        </p>
      </div>
    </div>

    <b-button
      :variant="request.is_processed ? 'outline-primary' : 'primary'"
      @click.prevent="toggleRequestProcess"
    >
      {{ request.is_processed ? 'Вернуть в работу' : 'Заявка обработана' }}
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { BButton } from 'bootstrap-vue'

import SiteRequest from '@/api/http/models/site-request/SiteRequest'

import SuperTag from '../../templates/components/SuperTag.vue'

export default {
  components: {
    SuperTag,
    BButton,
  },

  props: {
    request: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },

  methods: {
    async toggleRequestProcess() {
      const response = await SiteRequest.processed(this.currentSite.slug, this.request.uuid, !this.request.is_processed)

      if (response.status === 'success') {
        this.$emit('toggleRequestProcess', this.request.uuid)
      }
    },
  },
}
</script>

<style lang="scss">
  .history-item {
    padding: 20px;
    border-radius: 20px;
    background: #f7f7f8;

    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
    }
  }
</style>
