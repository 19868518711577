<template>
  <div class="pt-2">
    <div class="h1 mb-3">
      {{ request.name }}
    </div>
    <div class="mb-3">
      <div class="h2 mb-2">
        <feather-icon
          icon="UserIcon"
          size="21"
        />
        Клиент
      </div>
      <div class="mb-2 font-weight-bold">
        {{ request.name }}
      </div>
      <div class="mb-2 font-weight-bold">
        {{ request.phone_formatted }}
      </div>
      <div class="mb-2 font-weight-bold text-primary">
        {{ request.email }}
      </div>
    </div>
    <div class="w-75">
      <div
        v-for="(item, index) in history"
        :key="index"
        class="mb-2"
      >
        <history-request-item
          :request="item"
          @toggleRequestProcess="$emit('toggleRequestProcess', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HistoryRequestItem from './HistoryRequestItem.vue'

export default {
  components: {
    HistoryRequestItem,
  },

  props: {
    history: {
      type: Array,
      required: true,
    },

    request: {
      type: Object,
      required: true,
    },
  },
}
</script>
