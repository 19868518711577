<template>
  <div class="pt-2">
    <div class="mb-1">
      Создана <span class="text-primary">{{ request.created_at | momentFormat('DD MMM HH:mm') }}</span>
    </div>
    <div class="h1 mb-3">
      Запись на сервис
    </div>
    <div class="mb-3">
      <div class="h2 mb-2">
        <feather-icon
          icon="TagIcon"
          size="21"
          class="mr-1"
        />
        Детали заказа
      </div>
      <div class="mb-2">
        Статус:
        <br>
        <span class="pl-2">
          <b
            v-if="request.is_processed"
            class="text-success"
          >
            Обработана
          </b>
          <b
            v-else
            class="text-secondary"
          >
            Не обработана
          </b>
        </span>
      </div>
      <div class="mb-2">
        Дата / Время заявки:
        <br>
        <span class="pl-2">{{ request.created_at | momentFormat('DD MMM HH:mm') }}</span>
      </div>
      <div class="mb-2">
        Страница:
        <br>
        <span class="pl-2">
          <a
            :href="request.referer"
            target="_blank"
          >
            {{ request.request_title }}
          </a>
        </span>
      </div>
      <div class="mb-2">
        Место:
        <br>
        <span class="pl-2">{{ request.target_translated }}</span>
      </div>
      <div
        v-if="request.location"
        class="mb-2"
      >
        Локация:
        <br>
        <span class="pl-2">{{ request.location }}</span>
      </div>
      <div
        v-if="request.message"
        class="mb-2"
      >
        Сообщение:
        <br>
        <span class="pl-2">{{ request.message }}</span>
      </div>
      <div
        v-if="request.vin"
        class="mb-2"
      >
        VIN:
        <br>
        <span class="pl-2">{{ request.vin }}</span>
      </div>
      <div
        v-if="request.files.length"
        class="mb-2"
      >
        Прикреплённые файлы:
        <div
          v-for="file in request.files"
          :key="file"
          class="pl-2"
        >
          <a
            :href="file"
            target="_blank"
          >
            {{ file }}
          </a>
        </div>
      </div>
      <div
        v-if="request.additional_info"
        class="mb-2"
      >
        Дополнительная информация:
        <br>
        <span class="pl-2">{{ request.additional_info }}</span>
      </div>
    </div>
    <div>
      <div class="h2 mb-2">
        <feather-icon
          icon="UserIcon"
          size="21"
          class="mr-1"
        />
        Клиент
      </div>
      <div class="mb-2 font-weight-bold">
        {{ request.name }}
      </div>
      <div class="mb-2 font-weight-bold">
        {{ request.phone_formatted }}
      </div>
      <div class="mb-2 font-weight-bold text-primary">
        {{ request.email }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
}
</script>
