<template>
  <div>
    <div class="accordion-label__content">
      <div class="row align-items-center w-100">
        <div class="col-12 col-lg-2">
          Клиент
        </div>
        <div class="col-12 col-lg-2">
          Номер телефона
        </div>
        <div class="col-12 col-lg-2">
          <div class="text-center">
            Статус
          </div>
        </div>
        <div class="col-12 col-lg-2">
          <div
            class="order-tab-direction"
            :class="order === 'created_at' ?`order-tab-direction--${orderDirection}` : ''"
            @click.prevent="$emit('orderByCreatedAt')"
          >
            Дата
            <feather-icon
              icon="ChevronDownIcon"
            />
          </div>
        </div>
        <div class="col-12 col-lg-2">
          Тип заявки
        </div>
        <div class="col-12 col-lg-2 text-right">
          Действия
        </div>
      </div>
    </div>
    <div
      class="accordion"
      role="tablist"
    >
      <div
        v-for="request in requests"
        :key="request.uuid"
        class="accordion__item"
      >
        <div class="accordion-label accordion-label--disable-arrow">
          <div class="accordion-label__content">
            <div class="row align-items-center w-100">
              <div class="col-12 col-lg-2">
                {{ request.name }}
              </div>
              <div class="col-12 col-lg-2">
                {{ request.phone_formatted }}
              </div>
              <div class="col-12 col-lg-2">
                <div class="d-flex justify-content-center">
                  <b-form-checkbox
                    :checked="request.is_processed"
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-lg-2">
                {{ request.created_at | momentFormat }}
              </div>
              <div class="col-12 col-lg-2">
                {{ request.request_title }}
              </div>
              <div class="col-12 col-lg-2 text-right">
                <b-button
                  v-b-tooltip.hover.left="'Открыть'"
                  variant="link"
                  size="sm"
                  @click.prevent="openRequestPopup(request)"
                >
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <request-popup
      v-if="showRequestPopup"
      :key="currentRequestId"
      :request-id="currentRequestId"
      @hidden="hideRequestPopup"
      @toggleRequestProcess="$emit('toggleRequestProcess', $event)"
    />
  </div>
</template>

<script>
import { BButton, VBTooltip, BFormCheckbox } from 'bootstrap-vue'

import RequestPopup from './request-popup/RequestPopup.vue'

export default {
  components: {
    BButton,
    BFormCheckbox,
    RequestPopup,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    order: {
      type: String,
      required: true,
    },

    orderDirection: {
      type: String,
      required: true,
    },

    requests: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      showRequestPopup: false,
      currentRequestId: null,
    }
  },

  methods: {
    hideRequestPopup() {
      this.showRequestPopup = false
      this.currentRequestId = null
    },

    openRequestPopup(request) {
      this.showRequestPopup = true
      this.currentRequestId = request.uuid
    },

    handleToggleRequest(requestId) {
      this.$emit('updateRequestProcess', requestId)
    },
  },
}
</script>
