import HTTPService from '@/api/http/HTTPService'
import URLSearchParams from '@/@core/utils/getUrlParams'

export default {
  getAll: (siteSlug: string, query?: {page?: number, search?: string, order?: string, order_direction?: string}) => {
    const queryString = query ? URLSearchParams(query) : false
    return HTTPService.get(`site/${siteSlug}/client-request${queryString ? `?${queryString}` : ''}`)
  },
  getOne: (siteSlug: string, requestId: string) => HTTPService.get(`site/${siteSlug}/client-request/${requestId}`),
  processed: (siteSlug: string, requestId: string, isProcessed: boolean) => HTTPService.patch(`site/${siteSlug}/client-request/${requestId}`, {
    is_processed: isProcessed,
  }),
  history: (siteSlug: string, requestId: string) => HTTPService.get(`site/${siteSlug}/client-request/${requestId}/history`),
  export: (siteSlug: string, payload: string) => HTTPService.get(`site/${siteSlug}/client-request/export`, payload),
}
